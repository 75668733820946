<template>
  <span>
    <p>Terms and Conditions</p>

    1. Acceptance of Terms
    By accessing and using the website equal-finance.com («Website»), you agree to be bound by the following Terms and Conditions («Terms»). If you do not agree to these Terms, you should not use the Website.

    2. Eligibility
    By using the Website, you affirm that you are at least 18 years of age or are accessing the Website under the supervision of a parent or legal guardian. You must also comply with all applicable laws in your jurisdiction.

    3. Use of the Website
    You may use the Website for your personal and non-commercial purposes only. You agree not to engage in any activities that could harm the Website or its users. Unauthorized use, including hacking, scraping, or similar activity, is strictly prohibited.

    Prohibited activities include, but are not limited to:
    Interfering with the security or operation of the Website.
    Using the Website to distribute spam, viruses, or any other malicious content.
    Collecting or storing personal information about other users without their consent.
    4. Intellectual Property
    All content on the Website, including but not limited to text, graphics, logos, and software, is the property of equal-finance.com or its licensors. You may not reproduce, distribute, or otherwise use this content without written permission.

    5. Privacy Policy
    Our Privacy Policy, which governs how we collect, use, and protect your personal data, is an integral part of these Terms. By using the Website, you consent to our collection and use of your data as outlined in the Privacy Policy.

    6. Disclaimer of Warranties
    The Website is provided «as is» and «as available,» without any warranties, either express or implied. We do not guarantee that the Website will be available at all times, or that it will be free from errors or viruses.

    7. Limitation of Liability
    Equal-finance.com shall not be liable for any damages arising from the use or inability to use the Website, including but not limited to direct, indirect, incidental, or consequential damages.

    8. Indemnification
    You agree to indemnify and hold equal-finance.com harmless from any claims, losses, or damages arising out of your violation of these Terms or your use of the Website.

    9. Third-Party Links
    The Website may contain links to third-party websites. These links are provided for your convenience, and equal-finance.com does not endorse or assume any responsibility for the content or policies of these websites.

    10. Modifications to the Terms
    We reserve the right to modify these Terms at any time. Changes will be posted on this page, and your continued use of the Website signifies your acceptance of the updated Terms.

    11. Governing Law
    These Terms are governed by the laws of [Your Jurisdiction], and any disputes will be resolved in the courts of [Your Jurisdiction].

    12. Contact Information
    If you have any questions about these Terms, please contact us at:
    Email: support@equal-finance.com
  </span>
</template>
