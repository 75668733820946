<template>
  <div>
    <div class="ts-title">
      {{ $t("buyForm.third.title.1") }}
    </div>
    <div class="ts-header">
      <p>{{ $t("buyForm.third.title.2") }}</p>
      <p>
        {{ $t("buyForm.third.title.3") }} <a
          :href="scanUrl + 'address/' + address + '#tokentxns'"
          target="_blank"
        >{{ $t("buyForm.third.title.4") }}</a>.
      </p>
    </div>
    <div class="ts-divider" />
    <div class="ts-info">
      <div class="ts-info-element">
        <span class="ts-text">{{ $t("buyForm.third.type") }}</span>
        <span class="ts-bold">{{ token }}</span>
      </div>
    </div>

    <div class="ts-referral">
      <span class="ts-text">
        {{ $t("buyForm.third.received") }}
      </span>
    </div>

    <div class="ts-referral">
      <span class="ts-text">
        {{ $t("buyForm.third.support") }}
        <a
          v-if="isEqual"
          href="mailto:info@equal-finance.com"
        >
          {{ support }}
        </a>
        <span
          v-else
          class="ts-green"
          @click="openChat"
        >
          {{ support }}
        </span>
      </span>
    </div>
    <div class="ts-more-info ts-text">
      {{ $t("buyForm.third.projects") }} <RouterLink :to="{name: 'projects'}">
        {{ $t("buyForm.third.projectsRef") }}
      </RouterLink>.
    </div>
    <div class="ts-next">
      <AuButton
        center
        width="280px"
        type="primary"
        @click="$emit('next')"
      >
        {{ $t("buyForm.third.next") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThirdStage",
  props: {
    address: {
      type: String,
      default: ""
    },
    token: {
      type: String,
      default: ""
    },
    urlScan: {
      type: String,
      default: ""
    }
  },
  computed: {
    scanUrl() {
      return this.urlScan.length === 0 ? process.env.VUE_APP_BSCSCAN_BASE_URL : this.urlScan;
    },
    isEqual() {
      const equal = new RegExp("equal", "i");
      return equal.test(process.env.VUE_APP_TITLE);
    },
    support() {
      return this.isEqual ? "EQUAL Support." : "Autentic Support.";
    }
  },
  methods: {
    openChat() {
      window.carrotquest.open();
    }
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: underline;
  color: #279063;
}
.ts-title {
  font-size: 24px;
  border-bottom: 1px solid var(--color-70);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.ts-header {
  font-size: 20px;
}
.ts-divider {
  border-bottom: 3px solid green;
  width: 200px;
  margin: 20px 0;
}
.ts-info {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.ts-info-element {
  display: flex;
  gap: 10px;
}
.ts-referral {
  margin-bottom: 15px;
}
.ts-more-info {
  margin-bottom: 40px;
}
.ts-next {
  display: flex;
  justify-content: start;
}
.ts-text {
  font-size: 16px;
}
.ts-bold {
  font-size: 16px;
  font-weight: 600;
}
.ts-green {
  color: #279063;
  cursor: pointer;
}
</style>