<template>
  <ProgressLoader
    v-if="loading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />

  <div v-else>
    <AuBreadcrumb
      :pages="breadcrumb"
      :current-page-title="dividendName"
    />
    <div class="card dividends-history">
      <LastTransactionsTable
        :dividends-id="id"
        class="dividends-table"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";

import LastTransactionsTable from "@/components/lastTransactions/LastTransactionsTable.vue";
import ProgressLoader from "@/loaders/progress-bar";

export default {
  name: "DividendsHistory",

  components: {
    LastTransactionsTable,
    ProgressLoader
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      dividend: null,
      auCapital: null,
      loading: true
    };
  },

  computed: {
    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 2, name: this.$t("router.pages.dividends"), path: "/dividends-issuer/" + this.tokenId }
      ];

      if (this.capitalData) {
        breadcrumb.splice(1, 0, { key: 1, name: this.capitalData.tokenBase?.nameEng, path: "/project-card/" + this.capitalData.id });
      }

      return breadcrumb;
    },

    dividendName() {
      return this.dividend?.title || "Dividend";
    },

    tokenId() {
      return _.split(this.dividend?.auToken, "/")[3];
    },

    capitalData() {
      return _.first(this.auCapital);
    }
  },

  async mounted() {
    this.dividend = await this.getDividend(this.id);
    this.auCapital = await this.getAuCapitalsByParams({ tokenBaseId: this.tokenId });
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getDividend: "dividends/getDividend",
      getAuCapitalsByParams: "capitals/getAuCapitalsByParams"
    })
  }
};
</script>

<style scoped lang="scss">
.dividends-history{
  padding: 20px;

  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .dividends-table {
    margin: -20px;
  }
}
</style>